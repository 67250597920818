import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import SuperDeluxeRoomComp from '../components/super-deluxe-room-comp'
import SocialMediaFixed from '../components/socialmedia-fixed'

const SuperDeluxeRoom = () => {

    return (
        <>
            <Helmet>
                <title>Super Deluxe Room - Hotel Lohaghat Regency</title>
                <meta name="description" content="Book the Super Deluxe Room at Hotel Lohaghat Regency for a luxurious stay with stunning views, elegant decor, and all modern amenities to make your stay memorable." />
                <meta name="keywords" content="Super Deluxe room hotel Lohaghat, luxury hotel room, deluxe room booking, stay at Hotel Lohaghat Regency, hotel rooms in Lohaghat, best deluxe room in Lohaghat" />
                <link rel="canonical" href="https://www.lohaghatregency.com/rooms/deluxe-room" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Room",
                        "name": "Super Deluxe Room",
                        "url": "https://www.lohaghatregency.com/rooms/deluxe-room",
                        "telephone": "+91 7669834101"

                    })}
                </script>
            </Helmet>


            {<TopBanner />}
            {<Navbar />}
            {<SuperDeluxeRoomComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default SuperDeluxeRoom
