import React from "react";
import "../css/places-to-visit.css";

const PlacesToVisit = () => {
  return (
    <section className="places-to-visit-section">
      <h1 className="swipe">Places To Visit</h1>
      <br />
      <div className="places-to-visit-container">
        <div className="places-to-visit-card places-to-visit-card1 swipe">
          <div className="places-to-visit-card-content">
            <h2>PANCHESWAR MAHADEV TEMPLE</h2>
            <p>
            Pancheshwar Mahadev Temple is a sacred temple of Lord Shiva located at foothills of the India - Nepal border, on confluence of river Sharda and river Kali near Lohaghat in Champawat district.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card2 swipe">
          <div className="places-to-visit-card-content">
            <h2>MAYAWATI ASHRAM</h2>
            <p>

            Mayawati Ashram also known as the Advaita Ashram is one of the famous visitors attractions located in the Old Tea Estate area in Lohaghat region of Champawat district of Uttarakhand.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card3 swipe">
          <div className="places-to-visit-card-content">
            <h2>BANASUR KA KILA</h2>
            <p>
            This medieval-era fort was built in the memory of Banasur, thousand-armed son of mythological king Bali who was slayed by Lord Krishna. The fort, situated 7kms from Lohaghat in Champawat district
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card4 swipe">
          <div className="places-to-visit-card-content">
            <h2>ABBOTT MOUNT</h2>
            <p>
            Cliffed atop a hill, the dwarf hill station of Abbott Mount is snuggled in the Kali Kumaon region of Champawat district of Uttarakhand state. This picturesque hill station is perched at an elevation of 6,400 feet.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card5 swipe">
          <div className="places-to-visit-card-content">
            <h2>ABBOTT MOUNT CHURCH</h2>
            <p>

            In the solitary hill station of Abbott Mount perched at 6,400 feet above sea level, withstands an old rustic church called Abbott Mount Church built in 1942.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card6 swipe">
          <div className="places-to-visit-card-content">
            <h2>CHAMPAWAT</h2>
            <p>
            The famed city of Champawat is a town and Nagar Palika Parishad in the Champawat district of Uttarakhand. It is one of the eastern-most towns of Uttarakhand, proudly sitting at an elevation of 1,670 metres above sea level.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card7 swipe">
          <div className="places-to-visit-card-content">
            <h2>ARJUNESHWAR TEMPLE</h2>
            <p>

            The Arjuneshwar Temple, dedicated to Lord Shiva, is a revered site in Pithoragarh, Uttarakhand. According to legend, it was built by Arjuna, one of the Pandavas from the Mahabharata. Located at an elevation of 6000 feet, it provides a spiritual retreat alongside panoramic views of the surrounding hills.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card8 swipe">
          <div className="places-to-visit-card-content">
            <h2>BALESHWAR TEMPLE</h2>
            <p>

            The Arjuneshwar Temple, located in Pithoragarh, Uttarakhand, is a sacred site perched at an altitude of 6000 feet. Dedicated to Lord Shiva, it is believed to have been built by Arjuna, one of the Pandavas from the Mahabharata. The temple combines religious significance with the natural beauty of its hilltop location.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlacesToVisit;
