import React from "react";
import "../css/testimonial.css";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonial = () => {
  const navigate = useNavigate();

  return (
    <section className="testimonial-section">
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        spaceBetween={30}
        slidesPerView={1}
        style={{ maxWidth: "100%", minWidth: "300px", padding: "0px 0px" }}
        pagination={{ clickable: true }}
        // navigation={true}
        breakpoints={{
          320: {
            navigation: false,
          },
          480: {
            navigation: false,
          },
          628: {
            navigation: false,
          },
        }}
      >
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              I had a superb stay here. It is like home away home. Rooms are very clean and service is top notch. Owner itself make you feel like you are at home. Food is very tasty. The location is near Lohaghat bus stand. Hotel also has private parking space for 3-4 cars. I will stay here again during my next trip.
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Arunendra Vikram Singh</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              I had a great stay at Hotel Lohaghat Regency. The hotel was clean, comfortable, and well-maintained, with all the necessary facilities. A big shoutout to Golu, the caretaker, who was extremely helpful and attentive throughout our visit. His friendly nature and willingness to assist made our experience even better. Highly recommend this hotel if you're in Lohaghat!
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Ankit Sharma</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              
              Thanks for the hospitality to the staff over here. The staff was very cooperative and amenities were clearly hygiene.
              I would strongly recommend yo everyone if you guys are visiting Lohaghat in coming days.
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Birendra Kandpal</h2>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Testimonial;
