import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/hero.jpg";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Hotel Lohaghat Regency</h1>
        </h1>
        <p>
        Come and experience the magic of Hotel Lohaghat Regency. Let us take you on a journey of luxury, relaxation, and unforgettable memories. Book your stay with us today and get ready to embark on the adventure of a lifetime.

At Hotel Lohaghat Regency, your comfort and satisfaction are our top priorities. We take pride in delivering an exceptional experience to each and every guest who walks through our doors.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="Hotel Lohaghat Regency Image"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
