import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import ContactUs from '../components/contact-us';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us - Hotel Lohaghat Regency</title>
                <meta name="description" content="Get in touch with Hotel Lohaghat Regency for bookings, inquiries, or general questions. Our team is ready to assist you in planning your perfect stay in Lohaghat." />
                <meta name="keywords" content="Hotel Lohaghat Regency contact, Lohaghat hotel contact, Lohaghat booking, contact Hotel Lohaghat Regency, inquiries hotel, Lohaghat reservations, hotel support" />
                <link rel="canonical" href="https://www.lohaghatregency.com/contact" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Contact Us - Hotel Lohaghat Regency",
                        "url": "https://www.lohaghatregency.com/contact",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+91 7669834101",
                            "contactType": "Customer Service",
                            "areaServed": "IN",
                            "availableLanguage": "English"
                        },
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "opp. Picture Palace, The Mall Road, Lohaghat",
                            "addressRegion": "Uttarakhand",
                            "postalCode": "248179",
                            "addressCountry": "IN"
                        }
                    })}
                </script>
            </Helmet>
            <TopBanner />
            <Navbar />
            <ContactUs />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default ContactPage;
