import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import PlacesToVisit from '../components/places-to-visit';

const PlacesToVisitPage = () => {
    return (
        <>
            <Helmet>
                <title>Places to Visit in Lohaghat - Explore Attractions with Lohaghat Regency</title>
                <meta name="description" content="Discover the best tourist attractions in Lohaghat, including Pancheshwar Mahadev Temple, Mayawati Ashram, Banasur Ka Kila, Abbott Mount, Champawat, Abbott Mount Church, Arjuneshwar Temple, and Baleshwar Temple. Explore the beauty of the Queen of Hills." />
                <meta name="keywords" content="places to visit in Lohaghat, Pancheshwar Mahadev Temple, Mayawati Ashram, Banasur Ka Kila, Abbott Mount, Champawat, Abbott Mount Church, Arjuneshwar Temple, Baleshwar Temple, Lohaghat tourist attractions, Lohaghat sightseeing, Queen of Hills" />
                <link rel="canonical" href="https://www.lohaghatregency.com/places-to-visit" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "TouristDestination",
                        "name": "Lohaghat",
                        "url": "https://www.lohaghatregency.com/places-to-visit",
                        "telephone": "+91 7669834101",
                    })}
                </script>
            </Helmet>



            <TopBanner />
            <Navbar />
            <PlacesToVisit />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default PlacesToVisitPage;
